import {Routes} from '@angular/router';
import {AuthGuard} from './shared/services/auth/auth.guard';


export const rootRouterConfig: Routes = [
  {
    path: '',
    loadComponent: () => import('./views/home/home-one.component').then(m => m.HomeOneComponent),
  },
  { path: 'terms',
    loadComponent: () => import('./views/terms/terms.component').then(m => m.TermsComponent),
  },
  { path: 'privacy',
    loadComponent: () => import('./views/privacy/privacy.component').then(m => m.PrivacyComponent),
  },
  { path: 'start',
    loadComponent: () => import('./views/oauth/oauth.component').then(m => m.OauthComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'competition',
    loadComponent: () => import('./views/competition/competition.component').then(m => m.CompetitionComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadComponent: () => import('./views/profile/profile.component').then(m => m.ProfileComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadComponent: () => import('./views/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'join',
    loadComponent: () => import('./views/join/join.component').then(m => m.JoinCompetitionComponent),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '',
  },
];

