import {ApplicationConfig} from '@angular/core';

import {provideRouter, RouterConfigOptions, withRouterConfig} from '@angular/router';
import { rootRouterConfig } from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {AuthInterceptor} from './shared/services/authentication.interceptor';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      rootRouterConfig,
      withRouterConfig({
        scrollPositionRestoration: 'disabled',
        anchorScrolling: 'disabled',
      } as RouterConfigOptions),
    ),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideClientHydration(),
    provideHttpClient(),
    provideAnimationsAsync(),
  ]
};
