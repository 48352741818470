import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {LocalStorageService} from './local-storage.service';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storageService: LocalStorageService,
              private authService: AuthenticationService,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storageService.getItem('token');
    const session = this.storageService.getItem('notionUserId');

    let headers = req.headers;
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    else if (!token && session) {
      headers = headers.append('Session', session);
    }

    const authReq = req.clone({ headers });
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        return throwError(error);
      })
    );
  }
}
